/* -------------------------------- */
/* General Styles */
/* -------------------------------- */
.catering-delivery-page {
    padding: 20px;
    background-color: #f8f9fa;
}

/* -------------------------------- */
/* Header Section */
/* -------------------------------- */
.header-section {
    text-align: center;
    margin-bottom: 60px;
}

.header-image-container {
    position: relative;
    margin: 0 auto;
    width: 90%;
    border-radius: 5px;
    overflow: hidden;
    min-height: 300px; /* Ensures enough height for content */
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container while keeping aspect ratio */
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box; /* Ensures padding is included in the width */
}

.header-logo {
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.header-title {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 10px;
}

.header-subtitle {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 20px;
}

.header-cta {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #00b6d9;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.header-cta:hover {
    background-color: #007ea8;
}

/* -------------------------------- */
/* Who Benefits from Our Services Section */
/* -------------------------------- */
.use-cases-section {
    padding: 10px 0; /* Reduced padding for less spacing */
    text-align: center;
    background-color: #f8f9fa;
    margin-top: -60px;
    margin-bottom: 30px; /* Adjust spacing below the section */
}

.use-cases-section h2 {
    font-size: 2rem;
    color: #00b6d9;
    margin-top: 10px; /* Reduced margin at the top */
    margin-bottom: 10px; /* Reduced margin at the bottom */
}

.use-cases {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px; /* Space between title and content */
}

.use-case {
    width: 45%;
    margin-bottom: 20px;
    padding: 10px; /* Padding inside each use-case container */
    background-color: #fff; /* White background for each container */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.use-case h3 {
    color: #343a40;
    margin-bottom: 15px; /* Spacing below each heading */
    margin-top: 10px;
}

.use-case p {
    color: #6c757d;
}

.use-case-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 5px;
}

.use-case ul {
    padding-left: 20px; /* Indent the list from the left margin */
    text-align: left;   /* Align the text to the left */
    list-style-position: outside; /* Ensure the bullets are outside the list items */
}

.use-case li {
    margin-bottom: 10px; /* Add space between list items */
    line-height: 1.6;    /* Adjust line height for readability */
    text-align: left;    /* Ensure the text inside list items is aligned to the left */
}

/* -------------------------------- */
/* Commitment Section */
/* -------------------------------- */
.commitment-section {
    margin-bottom: 60px;
    padding: 40px;
}

.commitment-section h2 {
    color: #343a40;
    text-align: center;
    margin-bottom: 20px;
}

.commitment-section ul {
    list-style-type: disc;
    padding-left: 20px;
}

.commitment-section li {
    color: #6c757d;
    font-size: 1.1em;
    margin-bottom: 10px;
}

/* -------------------------------- */
/* Service Details Section */
/* -------------------------------- */
.service-details-section {
    margin-bottom: 60px;
    padding: 40px;
    background-color: #f0f2f5;
    border-radius: 5px;
}

.service-details-section h2 {
    color: #343a40;
    text-align: center;
    margin-bottom: 20px;
}

.service-details-section ul {
    list-style-type: disc;
    padding-left: 20px;
}

.service-details-section li {
    color: #6c757d;
    font-size: 1.1em;
    margin-bottom: 10px;
}

.highlight {
    color: #00b6d9;
    font-weight: bold;
}

/* -------------------------------- */
/* Contact Section */
/* -------------------------------- */
.contact-section {
    text-align: center;
    margin: 60px 0;
}

.contact-logo {
    display: block;
    margin: 0 auto 20px;
    max-width: 200px;
    height: auto;
}

.highlighted-contact {
    text-align: center;
    font-size: 1.2em;
    color: #00b6d9;
    font-weight: bold;
}

.contact-link {
    color: #00b6d9;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-link:hover {
    color: #007ea8;
}

.contact-section p {
    color: #6c757d;
    line-height: 1.6;
}

/* -------------------------------- */
/* Call to Action Section */
/* -------------------------------- */
.cta-section {
    text-align: center;
    background-color: #f0f2f5;
    padding: 40px;
    border-radius: 5px;
    margin-bottom: 60px;
}

.cta-section p {
    font-size: 18px;
    margin-bottom: 20px;
}

.cta-section .btn-primary {
    font-size: 18px;
    padding: 10px 20px;
}

/* -------------------------------- */
/* Responsive Adjustments */
/* -------------------------------- */
@media (max-width: 768px) {
    .header-image-container {
        width: 100%;
        min-height: 400px; /* More height to fit all content */
    }

   
    .header-image {
        object-fit: cover; /* Maintain cover for better visual appeal */
        height: 100%; /* Ensure the image covers the container */
    }

    .header-logo {
        max-width: 120px; /* Slightly smaller logo */
        margin-top: 20px; /* Add some margin on top */
    }

    .header-title {
        font-size: 1.5rem;
    }

    .header-subtitle {
        font-size: 0.9rem;
    }

    .header-cta {
        padding: 6px 12px;
        font-size: 0.9rem;
    }

    .use-cases {
        flex-direction: column; /* Stack items on mobile */
    }

    .use-case {
        width: 100%; /* Full width on mobile */
    }

    .use-case-image {
        max-height: 200px; /* Smaller image height on mobile */
    }

    .commitment-section,
    .use-cases-section,
    .service-details-section,
    .contact-section,
    .cta-section {
        padding: 20px 10px; /* Reduce padding on mobile */
        margin-bottom: 30px; /* Reduce margin between sections on mobile */
    }

    .use-case ul {
        padding-left: 20px; /* Indent the list from the left margin */
        text-align: left;   /* Align the text to the left */
        list-style-position: outside; /* Ensure the bullets are outside the list items */
    }

    .use-case li {
        margin-bottom: 10px; /* Add space between list items */
        line-height: 1.6;    /* Adjust line height for readability */
        text-align: left;    /* Ensure the text inside list items is aligned to the left */
    }
}
